import React from 'react'
import Layout from '../components/Layout'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import notFoundGif from '../img/404.gif'

const NotFoundPage = ({ data, location }) => {
    const jsonData = data.allArticlesJson.edges[0].node.articles
    return (
        <Layout data={data} jsonData={jsonData} location={location}>
            <>
                <section className="hero is-medium">
                    <div className="hero-body">
                        <div className="container">
                            <img src={notFoundGif} />
                            <p className="is-medium is-size-3">
                                <FormattedMessage id="404" />
                            </p>
                        </div>
                    </div>
                </section>
            </>
        </Layout>
    )
}

NotFoundPage.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
}

export default NotFoundPage

export const pageQuery = graphql`
    query NotFoundPageQuery {
        site {
            siteMetadata {
                languages {
                    defaultLangKey
                    langs
                }
            }
        }
        allArticlesJson(filter: { title: { eq: "home" } }) {
            edges {
                node {
                    articles {
                        en
                        ka
                    }
                }
            }
        }
        markdownRemark {
            html
            frontmatter {
                id
                title
            }
            fields {
                slug
            }
        }
    }
`
